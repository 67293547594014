import {
  Button,
  Card,
  Modal,
  Radio,
  Row,
  Select,
  DatePicker,
  Tabs,
  notification,
  Popconfirm,
  Progress,
  message,
  Popover,
} from "antd";
import React, { memo, useEffect, useMemo, useState } from "react";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { useParams } from "react-router-dom";
import CRUDComponent from "../../../component/common/CRUD-Component";
import Heading from "../../../component/common/Heading";
import { PlusCircleOutlined } from "@ant-design/icons";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import useHttp from "../../../hooks/use-http";
import { EditOutlined } from "@ant-design/icons";
import {
  PERMISSION,
  TASK_APPROVAL_FORMS,
  TASK_APPROVAL_OPTION,
  apiGenerator,
  convertUTCToLocal,
} from "../../../util/functions";
import ViewTask from "../../../component/taskApproval/ViewTask";
import WorkProof from "../../../component/taskApproval/WorkProof";
import ModalFormCreator from "../../../component/common/ModalFormCreator";
import {
  taskApprovalExporAlltData,
  taskApprovalExportData,
} from "../../../util/exportData";
import BulkAction from "./BulkAction";
import dayjs from "dayjs";
import Services from "../../../util/API/service";
import { CSVLink } from "react-csv";

const TaskApproval = ({ admindetails, permissionId, isAdmin = false }) => {
  const accessPermission = +admindetails?.access
    ?.split(",")
    ?.filter((ele) => ele.includes(permissionId))[0]
    ?.split("-")[1];
  const api = useHttp();
  const API = useHttp();
  const { id } = useParams();
  const [statusTask, setStatusTask] = useState([]);
  const [openModal, setOpenModal] = useState(null);
  const [selectedDates, setSelectedDates] = useState({});
  const [modalData, setModalData] = useState(null);
  const [progress, setProgress] = useState(0);
  const [taskList, setTaskList] = useState(null);
  const [selectedTaskId, setSelectedTaskId] = useState(null);
  const [activeForm, setActiveForm] = useState(null);
  const [taskStatus, setTaskStatus] = useState(null);
  const [reload, setRealod] = useState(false);
  const [bulkAction, setBulkAction] = useState(false);
  const [exportData, setExportData] = useState([]);
  const [numberOfRecord, setNumberOfRecord] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);

  const detailsModal = {
    taskDetails: {
      title: "Task Details",
      child: <ViewTask data={modalData} />,
      width: 900,
    },
    workProof: {
      title: "Proof of Work",
      child: <WorkProof MultipleImages={modalData} />,
      width:
        modalData?.proofOfWork?.length && modalData?.taskStep?.length
          ? 1000
          : 800,
    },
  };

  useEffect(() => {
    api.sendRequest(
      CONSTANTS.API.Task_Management.getAll,
      (res) => {
        const option = res?.data?.rows?.map((task) => {
          return { Label: task?.name, value: task?.id, id: task?.id };
        });
        setTaskList(option);
      }
      // { status: "Active" }
    );
  }, []);

  const onChange = (dates, dateStrings) => {
    if (dates) {
      setSelectedDates({
        startDate: dateStrings[0],
        endDate: dateStrings[1],
      });
    } else {
      setSelectedDates({});
    }
  };

  const OnFormSubmitBtn = (res) => {
    const payload = { ...res };
    payload["status"] = activeForm?.form;
    api.sendRequest(
      apiGenerator(CONSTANTS.API.UserTask.updateModal, {
        dataId: activeForm?.id,
      }),
      (res) => {
        activeForm?.setRefresh((pr) => !pr);
        setActiveForm(null);
      },
      payload,
      "Data updated successfully"
    );
  };

  const disabledDate = (current) => {
    return current && current > dayjs().endOf("day");
  };

  const memoizedExtraQuery = useMemo(() => {
    return {
      ...(taskStatus && { status: taskStatus }),
      ...(id && { taskId: id }),
      ...(selectedTaskId && { taskId: selectedTaskId }),
      ...(selectedDates?.startDate && {
        ...selectedDates,
        endDate: dayjs(selectedDates?.endDate)
          .add(1, "day")
          .format("YYYY-MM-DD"),
      }),
    };
  }, [taskStatus, id, selectedDates, selectedTaskId]);

  // const taskApprovalExport = (data) => {
  //   const maxLength = Math.max(
  //     ...data?.map((item) => (item.proofOfWork ? item.proofOfWork.length : 0))
  //   );
  //   const proofOfWorkFiels = maxLength
  //     ? Array.from({ length: maxLength }, (_, i) => [
  //         `link${i + 1}`,
  //         `Link ${i + 1}`,
  //       ])
  //     : [];

  //   setExportDataFields([...taskApprovalExportData, ...proofOfWorkFiels]);
  // };

  const getOneUserTask = (userTaskId, componentName) => {
    api.sendRequest(
      apiGenerator(CONSTANTS.API.UserTask.getOne, { id: userTaskId }),
      (res) => {
        if (res?.data?.proofOfWork || res?.data?.task?.taskSteps?.length) {
          const taskStepProof = res?.data?.task?.taskSteps?.flatMap((task) => {
            if (task?.isPOWNeeded) {
              return task?.userTaskSteps?.flatMap(
                (step) =>
                  step?.proofOfWork?.map((proof) => ({
                    stepNumber: task?.stepNumber,
                    stepName: task?.name,
                    image: proof,
                  })) || []
              );
            }
            return [];
          });
          const finalProofOfWork = (res?.data?.proofOfWork || []).map(
            (url) => ({
              image: url,
            })
          );
          setOpenModal(componentName);
          setModalData({
            proofOfWork: [...taskStepProof, ...finalProofOfWork],
            taskStep: res?.data?.task?.taskSteps,
          });
        } else {
          notification.error({
            message: "Data not found",
          });
        }
      }
      // { status: "Active" }
    );
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const approveAll = () => {
    if (!selectedTaskId && !id) {
      message.error({ content: "Please Select Task" });
      return;
    }
    const status = `?status=Pending&taskId=${selectedTaskId ?? id}`;
    setProgress(0);
    api.sendRequest(
      apiGenerator(CONSTANTS.API.UserTask.getAll, {}, status),
      (res) => {
        api.sendRequest(
          apiGenerator(
            CONSTANTS.API.UserTask.getAll,
            {},
            status + "&limit=" + res.data?.count
          ),
          async (res) => {
            let count = 0;
            for (const element of res.data.rows) {
              let payload = {
                rating: 5,
                status: "Accepted",
              };
              if (element.status !== payload.status) {
                try {
                  await delay(500);
                  const response = await Services.patch(
                    CONSTANTS.API.UserTask.updateModal.endpoint.replace(
                      ":dataId",
                      element.id
                    ),
                    payload
                  );
                  console.log(response.status);
                } catch (error) {
                  console.log(error);
                }
              }
              count++;
              setProgress((count / res.data?.count) * 100);
            }
            setProgress(null);
            setRealod((pre) => !pre);
          }
        );
      }
    );
  };

  const formateCSVData = (data) => {
    let exportCSVHeader = [...taskApprovalExporAlltData];
    if ((id || selectedTaskId) && data?.[0]?.task?.taskSteps?.length) {
      const stepPOWHeader = data?.[0]?.task?.taskSteps
        ?.filter((stp) => stp?.isPOWNeeded)
        ?.map((stp) => [`${stp.name}_POW`, `${stp.name}_POW`]);
      exportCSVHeader.splice(
        6,
        0,
        ["isStepWiseTask", "Is Step Wise Task"],
        ["numberOfTaskStep", "Number Of Task Step"],
        ["taskSteps", "Task Steps"],
        ["complatedTaskStep", "Number of Complated Task Step"]
      );
      exportCSVHeader.splice(12, 0, ...stepPOWHeader);
    }
    const newData = data?.map((item) => {
      const mergedArray = item?.task?.taskSteps?.map((task) => {
        const matchingUserTaskStep = item?.userTaskSteps?.find(
          (userTask) => userTask.taskStepId === task.id
        );
        if (matchingUserTaskStep) {
          return { ...task, ...matchingUserTaskStep };
        }
        return task; // Return task as-is if no match is found
      });
      return {
        no: item?.id,
        action: item?.status,
        name: item?.user?.name,
        mobile: item?.user?.mobile,
        taskId: item?.taskId,
        taskName: item?.task?.name,
        adminMessage: item?.adminMessage,
        ...((id || selectedTaskId) &&
          item?.task?.taskSteps?.length && {
            numberOfTaskStep: item?.task?.taskSteps?.length ?? "-",
            isStepWiseTask: item?.task?.isStepWiseTask ? "Yes" : "No",
            taskSteps: item?.task?.taskSteps
              ?.map((step) => `${step?.stepNumber}. ${step?.name}`)
              .join("\n"),
            complatedTaskStep:
              item?.userTaskSteps?.filter((step) => step.status === "completed")
                ?.length ?? "-",

            ...mergedArray?.reduce((acc, item) => {
              if (item?.isPOWNeeded && item?.status && item?.proofOfWork) {
                acc[`${item.name}_POW`] = item?.proofOfWork?.join("\n");
              }
              return acc;
            }, {}),
          }),
        taskSubmissionDateTime: item?.submissionDateTime
          ? convertUTCToLocal(item?.submissionDateTime)
          : "",
        ...item?.proofOfWork?.reduce((acc, link, index) => {
          acc[`link${index + 1}`] = link;
          return acc;
        }, {}),
      };
    });
    return { newData, header: exportCSVHeader };
  };

  const fetchDataForCSV = () => {
    if (numberOfRecord !== null && numberOfRecord !== undefined) {
      setBtnLoading(true);
      API.sendRequest(
        CONSTANTS.API.UserTask.getAll,
        async (res) => {
          if (!res?.data?.rows?.length) {
            setExportData([]);
            return;
          }
          const formateData = formateCSVData(res?.data?.rows);
          let CSVData = [];
          CSVData[0] = formateData?.header?.map((el) => el[1]); // Header row
          if (formateData?.newData?.length)
            formateData?.newData?.map((item, index) => {
              CSVData[index + 1] = formateData?.header
                ?.map((el) => el[0])
                ?.map((val) => {
                  if (item != null && val in item) return item[val];
                  return "";
                });
              return 0;
            });
          setExportData(CSVData);
          setBtnLoading(false);
        },
        {
          ...memoizedExtraQuery,
          limit: numberOfRecord,
          page: 1,
          isAdminReport: true,
        }
      );
    }
  };

  useEffect(() => {
    api.sendRequest(
      CONSTANTS.API.UserTask.taskCount,
      (res) => {
        const taskSubmissions = res?.data?.reduce((acc, item) => {
          acc[item.status] = item.count;
          acc.total = (acc.total || 0) + item.count;
          return acc;
        }, {});
        setStatusTask([
          {
            label: `All (${taskSubmissions?.total || 0})`,
            value: null,
          },
          {
            label: `In-Progress (${taskSubmissions?.InProgress || 0})`,
            value: "InProgress",
          },
          {
            label: `Pending (${taskSubmissions?.Pending || 0})`,
            value: "Pending",
          },
          {
            label: `Resubmit (${taskSubmissions?.Resubmit || 0})`,
            value: "Resubmit",
          },
          {
            label: `Accepted (${taskSubmissions?.Accepted || 0})`,
            value: "Accepted",
          },
          {
            label: `Rework (${taskSubmissions?.Rework || 0})`,
            value: "Rework",
          },
          {
            label: `Reject (${taskSubmissions?.Reject || 0})`,
            value: "Reject",
          },
        ]);
      },
      {
        ...(selectedDates?.startDate && {
          ...selectedDates,
          endDate: dayjs(selectedDates?.endDate)
            .add(1, "day")
            .format("YYYY-MM-DD"),
        }),
        ...(id && { taskId: id }),
        ...(selectedTaskId && { taskId: selectedTaskId }),
      }
    );
  }, [selectedDates, selectedTaskId, id, reload]);

  return (
    <>
      <div>
        {progress ? <Progress percent={progress} size="small" /> : ""}
        <Card className="my-5">
          <Heading>Task Approval List</Heading>
          <Row className="flex justify-between items-baseline">
            {statusTask?.length && (
              <Radio.Group
                value={taskStatus}
                onChange={(e) => setTaskStatus(e.target.value)}
                className="mt-6"
              >
                {statusTask?.map((st) => (
                  <Radio.Button value={st?.value}>{st?.label}</Radio.Button>
                ))}
              </Radio.Group>
            )}
            <div className="flex gap-2">
              {isAdmin && id && (
                <div className="flex gap-3">
                  <a
                    href="/bulk-action.csv"
                    className="flex gap-1 text-sm items-end"
                  >
                    Sample file <FaCloudDownloadAlt size={20} />
                  </a>
                  <Button
                    type="primary"
                    className="flex"
                    icon={<PlusCircleOutlined className="text-xl" />}
                    onClick={() => setBulkAction((pre) => !pre)}
                  >
                    Bulk Action
                  </Button>
                </div>
              )}
              <div className="flex">
                {!id && (
                  <Select
                    showSearch
                    placeholder={"Select Task"}
                    className="w-48 me-3"
                    allowClear
                    onChange={(value) => setSelectedTaskId(value)}
                    filterOption={(input, option) =>
                      option?.children
                        ?.toLowerCase()
                        ?.includes(input?.toLowerCase())
                    }
                  >
                    {taskList?.map((item) => (
                      <Select.Option key={`role_${item.id}`} value={item.value}>
                        {item.Label ? item.Label : item.value}
                      </Select.Option>
                    ))}
                  </Select>
                )}
                {admindetails?.role === "Admin" && (
                  <Popconfirm
                    onConfirm={() => approveAll()}
                    title={"Are you sure"}
                  >
                    <Button type="primary" className="me-3">
                      Approve All Pending
                    </Button>
                  </Popconfirm>
                )}
                <DatePicker.RangePicker
                  className={`${
                    selectedDates?.startDate && selectedDates?.endDate
                      ? "w-[15rem]"
                      : "w-[2.5rem] hide-arrow !ps-2"
                  }`}
                  allowClear={true}
                  value={
                    selectedDates?.startDate && selectedDates?.endDate
                      ? [
                          dayjs(selectedDates.startDate),
                          dayjs(selectedDates.endDate),
                        ]
                      : []
                  }
                  onChange={onChange}
                  format="YYYY-MM-DD"
                  disabledDate={disabledDate}
                />
                {(admindetails?.role === "Admin" || isAdmin) &&
                  (exportData?.length ? (
                    <CSVLink
                      filename={`TaskApprovalTable.csv`}
                      data={exportData}
                      onClick={() => {}}
                    >
                      <Button
                        className="float-right ms-2"
                        type="primary"
                        disabled={!exportData?.length}
                        ghost
                        onClick={() => {}}
                      >
                        Export CSV
                      </Button>
                    </CSVLink>
                  ) : (
                    <Popover
                      content={
                        Object.keys(memoizedExtraQuery)?.length
                          ? null
                          : "Use at least one filter."
                      }
                    >
                      <Button
                        className="float-right ms-2"
                        type="primary"
                        loading={btnLoading}
                        disabled={!Object.keys(memoizedExtraQuery)?.length}
                        ghost
                        onClick={() => fetchDataForCSV()}
                      >
                        Fetch Data
                      </Button>
                    </Popover>
                  ))}
              </div>
            </div>
          </Row>
          <Row>
            <CRUDComponent
              reload={reload}
              title="TaskApproval"
              exportAllData={
                admindetails?.role === "Admin" && taskApprovalExporAlltData
              }
              // accessPermission={accessPermission}
              GET={{
                API: CONSTANTS.API.UserTask.getAll,
                extraQuery: memoizedExtraQuery,
                DataModifier: (res, API, Setrefresh, extraData) => {
                  setExportData([]);
                  setNumberOfRecord(extraData?.data?.count);
                  return res?.map((data) => {
                    return {
                      ...data,
                      no: data?.id,
                      image: {
                        image: data?.user?.image,
                        name: data?.user?.name,
                      },
                      action: data?.status,
                      name: data?.user?.name,
                      mobile: data?.user?.mobile,
                      taskId: data?.taskId,
                      taskName: data?.task?.name,
                      taskDetails: {
                        id: "taskDetails",
                        onClick: (id) => {
                          if (!data?.taskId) return;
                          const GET_TASK_API = apiGenerator(
                            CONSTANTS.API.Task_Management.getOne,
                            {
                              id: data?.taskId,
                            }
                          );
                          api.sendRequest(GET_TASK_API, (res) => {
                            if (!res) return;
                            setModalData(res?.data);
                            setOpenModal(id);
                          });
                        },
                      },
                      ...data?.proofOfWork?.reduce((acc, link, index) => {
                        acc[`link${index + 1}`] = link;
                        return acc;
                      }, {}),
                      proofOfWorkList: data?.proofOfWork?.join("\n"),
                      proofOfWork: {
                        id: "workProof",
                        onClick: (id) => {
                          getOneUserTask(data?.id, id);
                        },
                      },
                      taskSubmissionDateTime: convertUTCToLocal(
                        data?.submissionDateTime
                      ),
                      verifyBy: data?.approveBy ? data?.approveBy : "-",
                      status: (
                        <Select
                          className="w-28"
                          placeholder={"Select"}
                          disabled={PERMISSION[accessPermission] === "READ"}
                          value={
                            ["Accepted", "Reject", "Rework"].includes(
                              data?.status
                            )
                              ? data?.status
                              : null
                          }
                          onClear={() => {
                            setActiveForm(null);
                          }}
                          onChange={(value, f) => {
                            setActiveForm({
                              id: data?.id,
                              form: value,
                              setRefresh: Setrefresh,
                            });
                            console.log(value);
                          }}
                        >
                          {TASK_APPROVAL_OPTION.map((item) => (
                            <Select.Option key={`${item.value}`}>
                              {item.label ? item.label : item.value}
                            </Select.Option>
                          ))}
                        </Select>
                      ),
                    };
                  });
                },
                column: CONSTANTS.TABLE.TASKAPPROVAL,
              }}
              isSearch
              UPDATE={{
                API: CONSTANTS.API.UserTask.update,
                message: "Message Updated successfully",
                modaltitle: "Message",
                modalFields: CONSTANTS?.FORM_FIELD?.TASK_APPROVAL_MESSAGE,
                // payloadModifier: (res) => res,
              }}
            />
          </Row>
        </Card>
      </div>
      <Modal
        title={detailsModal[openModal]?.title}
        centered
        open={openModal}
        onCancel={() => {
          setOpenModal(null);
          setModalData(null);
        }}
        width={detailsModal[openModal]?.width}
        footer={null}
      >
        {openModal && detailsModal[openModal]?.child}
      </Modal>
      <ModalFormCreator
        loading={api.isLoading}
        open={activeForm}
        onCreate={OnFormSubmitBtn}
        onCancel={() => {
          setActiveForm((pre) => !pre);
        }}
        menuFields={
          CONSTANTS.FORM_FIELD[TASK_APPROVAL_FORMS[activeForm?.form]?.form]
        }
        name={TASK_APPROVAL_FORMS[activeForm?.form]?.modaltitle}
        SubmitName={"Submit"}
      />
      {id && (
        <BulkAction
          bulkAction={bulkAction}
          setBulkAction={setBulkAction}
          setRealod={setRealod}
          api={api}
          id={id}
        />
      )}
    </>
  );
};

export default memo(TaskApproval);
